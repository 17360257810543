.blogs {
  padding-top: 100px;

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}

.blogItem {
  border-radius: 20px;
  border: 1px solid #5538FF33;
  background-color: #5538FF05;
  transition: all .15s;
  padding: 32px 24px;

  &__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 28px;
    line-height: 39px;
    font-weight: 600;
    height: 72px;
    margin-bottom: 27px;
  }

  &__date {
    font-size: 14px;
    line-height: 20px;
    opacity: 0.5;
    margin-bottom: 20px;
  }

  &__link {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #0D0131;
    transition: all .15s;
    position: relative;
    width: 98px;
    display: block;

    &:hover {
      color: #5538FF;
    }

    &:after {
      right: 0;
      top: 25%;
      position: absolute;
      content: "";
      width: 7px;
      height: 7px;
      border-top: 1px solid #0D0131;
      border-right: 1px solid #0D0131;
      transform: rotateZ(45deg);
      transition: all .15s;
    }
  }

  &__link:hover {
    &:after {
      border-top: 1px solid #5538FF;
      border-right: 1px solid #5538FF;
    }
  }

  &:hover {
    background-color: #5538FF1A;
  }
}

@media only screen and (max-width: 768px) {
  .blogs {
    padding-top: 60px;

    &__list {
      grid-template-columns: 1fr;
    }
  }

  .blogItem {
    &__title {
      font-size: 20px;
      line-height: 26px;
      height: 55px;
    }
  }
}