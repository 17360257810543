.getStarted {
  padding: 90px 0 180px;
}

.box {
  border: 1px solid #4427C2;
  border-radius: 40px;
  padding: 80px 40px 105px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 10%;

  &__decorLeft {
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    width: 748px;
    height: 445px;
    border-top-left-radius: 40px;
  }

  &__decorRight {
    position: absolute;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    width: 455px;
    height: 445px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
  }

  h3 {
    position: relative;
    text-align: center;
    font-size: 80px;
    line-height: 80px;
    margin-bottom: 40px;
    font-weight: 600;
    background: linear-gradient(180deg, #FFFFFF 34.87%, #5C5983 85.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__actions {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 25px;
    margin-bottom: 60px;

    button {
      width: 202px;
      height: 56px;
      border-radius: 1000px;

      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
      }
    }

    a {
      position: relative;
      padding-right: 20px;
      font-size: 16px;
      font-weight: 500;
      line-height: 16px;

      &:after {
        right: 0;
        top: 25%;
        position: absolute;
        content: '';
        width: 8px;
        height: 8px;
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        transform: rotateZ(45deg);
      }
    }
  }

  &__login {
    display: flex;
    justify-content: center;

    a {
      color: #FFDA18;
    }
  }
}

@media only screen and (max-width: 768px) {
  .getStarted {
    padding: 90px 0 20px;
  }

  .box {
    padding: 40px 25px 50px;

    h3 {
      text-align: start;
      font-size: 44px;
      line-height: 44px;
    }

    &__decorRight, &__decorLeft {
      display: none;
    }
  }
}