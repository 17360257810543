.outer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;

  .popup {
    min-width: 280px;
    min-height: 200px;
    max-width: 500px;
    width: 100%;
    padding: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F2F0F5;
    border-radius: 20px;
    
    &__img {
      width: 64px;
      height: 64px;
      margin-bottom: 10px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__confirmationText {
      flex-grow: 1;
      color: #282828;
      font-weight: 500;
      margin-bottom: 30px;
    }

    &__submit {
      min-width: 180px;
      padding: 12px 20px;
      border-radius: 100px;
    }
  }
}