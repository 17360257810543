.mainAbout {
  background-color: #0D0131;
}

.content {
  max-width: 1440px;
  padding: 0 40px 48px;
  margin: 0 auto;
  background-repeat: no-repeat;
  position: relative;

  a, p, span, li, h1, h2, h3, h4, h5, h6 {
    color: #fff;
  }
}

@media only screen and (max-width: 768px) {
  .content {
    padding: 0 20px 24px;
  }

  .backgroundDecor {
    pointer-events: none;
    position: absolute;
    background-size: 100%;
    top: 0;
    right: 0;
    z-index: 0;
    width: 100%;
    max-height: 882px;
    max-width: 398px;
    height: 100%;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 767px) {
  .backgroundDecor {
    pointer-events: none;
    position: absolute;
    background-size: 100%;
    top: 0;
    right: 0;
    z-index: 0;
    width: 100%;
    max-width: 1265px;
    max-height: 570px;
    height: 100%;
    background-repeat: no-repeat;
  }
}

