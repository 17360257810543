.exploreApis {
  margin-bottom: 260px;

  h2 {
    background: linear-gradient(180deg, #FFFFFF 34.87%, #5C5983 85.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 80px;
    line-height: 80px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 80px;
  }
}

.box {
  width: 100%;
  padding: 16px 16px 24px;
  border: 1px solid #4427C2;
  border-radius: 20px;

  &__image {
    display: flex;
    justify-content: center;

    img {
      object-fit: cover;
      max-width: 100%;
      height: 168px;
    }
  }

  &__title {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .exploreApis {
    margin-bottom: 180px;

    h2 {
      font-size: 44px;
      line-height: 44px;
    }
  }
  .wrapper {
    display: grid;
    gap: 20px;
    grid-template-areas: inherit;
    grid-template-columns: 1fr;
  }

  .box {

    &__image {
      margin-bottom: 28px;
    }

    &__title {
      margin-bottom: 16px;
      font-weight: 600;
      font-size: 28px;
      line-height: 28px;
    }

    &__description {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .wrapper {
    display: grid;
    gap: 20px;
    grid-template-areas:
            "item1 item1 item2 item2 item3 item3"
            "item4 item4 item4 item5 item5 item5";
  }

  .box {
    max-width: 440px;
    min-height: 340px;

    &__image {
      margin-bottom: 40px;
    }

    &__title {
      margin-bottom: 20px;
      font-size: 32px;
      font-weight: 600;
      line-height: 32px;
    }

    &__description {
      font-size: 18px;
      line-height: 25px;
    }
  }

  .box:nth-child(1) {
    grid-area: item1;
  }
  .box:nth-child(2) {
    grid-area: item2;
  }
  .box:nth-child(3) {
    grid-area: item3;
  }
  .box:nth-child(4) {
    grid-area: item4;
    max-width: 670px;
  }
  .box:nth-child(5) {
    grid-area: item5;
    max-width: 670px;
  }

}