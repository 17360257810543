.pricingFooter {
  margin-top: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__info {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: -0.05em;
    display: flex;
    flex-direction: column;
  }

  &__socials {
    display: flex;
    align-items: center;
    column-gap: 20px;

    img {
      width: 32px;
      height: 32px;
    }
  }
}