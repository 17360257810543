.aboutHeader, .aboutHeaderLight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 0;
  margin-bottom: 15px;
}

.navigation .links,
.navigationActive .links,
.navigation .linksLight,
.navigationActive .linksLight {
  display: flex;
  align-content: center;
  column-gap: 20px;
  position: relative;
}

.auth, .authLight {
  display: flex;
  align-content: center;
  column-gap: 20px;
  position: relative;
  z-index: 10;

  a {
    font-weight: 500;
  }

  &__login {
    padding: 12px 10px;
  }

  &__register {
    border-radius: 100px;
    padding: 12px 20px;
    color: white;
  }
}

.authLight__login {
  color: #0D0131;
}

.navigation .linksLight,
.navigationActive .linksLight {
  a, span {
    color: #0D0131;
  }
}

@media only screen and (max-width: 960px) {
  .auth {
    column-gap: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .aboutHeader, .aboutHeaderLight {
    position: relative;
  }

  .mobileNav {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }

  .navigation, .navigationActive {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: -2000px;
    top: 100%;
    background: #0D0131;
    background: linear-gradient(215deg, rgba(166, 196, 3, 1) 0%, rgba(105, 55, 34, 1) 20%, rgba(93, 10, 57, 1) 33%, rgba(67, 0, 130, 1) 45%, rgba(49, 0, 86, 1) 100%);

    &__footer {
      margin-top: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 20px;
      padding-bottom: 40px;
    }

    &__socials {
      display: flex;
      align-items: center;
      column-gap: 20px;
    }

    .links, .linksLight {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      a {
        position: relative;
        padding: 15px 20px 15px 0;
        font-size: 1.5rem;
        text-align: right;

        &:before {
          content:'';
          position: absolute;
          right: 20px;
          bottom: 0;
          width: calc(100% - 20px);
          height: 1px;
          background: #ffffff;
        }
      }
    }

    z-index: 1;
    transition: all .2s;
  }

  .navigationActive {
    padding-left: 20px;
    left: -20px;
    width: calc(100vw - 0px);
    height: calc(100vh - 98px);
  }

  .aboutHeaderLight {
    .navigation, .navigationActive {
      background: rgb(255,255,255);
      background: linear-gradient(215deg, rgba(255,255,255,1) 0%, rgba(248,248,248,1) 70%, rgba(183,171,255,1) 90%, rgba(85,56,255,1) 100%);
    }

    .navigationActive {
      z-index: 10;
    }

    .linksLight {
      a:before {
        background: #0D0131;
      }
    }
  }
}

.burger, .burgerClose {
  width: 28px;
  display: flex;
  justify-content: center;
  height: 28px;
  align-items: center;
  transition: all .15s;

  span {
    width: 24px;
    height: 2px;
    background-color: #fff;
    position: relative;


    &:before, &:after {
      position: absolute;
      top: -6px;
      left: 0;
      content: '';
      width: 24px;
      height: 2px;
      background-color: #fff;
    }

    &:after {
      top: 6px;
    }
  }
}

span.burgerLight {
  background-color: #0D0131;

  &:before, &:after {
    background-color: #0D0131 !important;
  }
}

.burgerClose {
  span {
    background-color: transparent;
    transition: all .15s;

    &:before, &:after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 24px;
      height: 2px;
      background-color: #fff;
      transform: rotateZ(45deg);
      transition: all .15s;
    }

    &:after {
      top: 0;
      transform: rotateZ(-45deg);
      transition: all .15s;
    }
  }
}

.active {
  font-weight: 600;

  span {
    color: #5538FF !important;
  }
}