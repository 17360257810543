.BlogView {
  margin-top: 60px;

  &__date {
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    opacity: 0.5;
    margin-bottom: 40px;
  }

  &__title {
    font-size: 54px;
    line-height: 70px;
    letter-spacing: -0.04em;
    background: linear-gradient(180deg, #300097 0%, #10003F 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: 0 auto 28px;
    max-width: 80%;
    font-weight: 600;
  }

  &__description {
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 60px;
  }

  &__block:not(:last-of-type) {
    margin-bottom: 40px;
  }

  &__block {
    h4 {
      font-size: 28px;
      font-weight: 600;
      line-height: 39.2px;
      margin-bottom: 28px;
    }

    h5 {
      font-size: 26px;
      margin-bottom: 20px;
    }

    p {
      font-size: 16px;
      line-height: 22.4px;

      a {
        text-decoration: underline;
      }
    }

    p:not(:last-child), ul {
      margin-bottom: 20px;
    }

    ul {
      padding-left: 30px;
    }

    .subList {
      padding-top: 10px;
      margin-bottom: 10px;
      list-style: initial;
    }

    li {
      list-style: initial;
    }

    .numericList > li {
      list-style: auto;
    }

    li:not(:last-child) {
      margin-bottom: 5px;
    }

    p.beforeList, span.beforeList {
      margin-bottom: 10px;
    }

    span.beforeList {
      display: block;
    }

    span.beforeList, .highlight {
      font-weight: 600;
    }
  }

  &__action {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    padding-bottom: 120px;
  }

  &__blogsTitle {
    font-weight: 600;
    font-size: 44px;
    line-height: 57px;
    background: linear-gradient(180deg, #300097 0%, #10003F 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -0.04em;
    text-align: center;
  }
}

.back {
  position: relative;
  padding-left: 16px;

  span {
    font-size: 16px;
    line-height: 16px;
    font-weight: 500;
    color: #5538FF;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) rotateZ(45deg);
    width: 8px;
    height: 8px;
    border-bottom: 2px solid #5538FF;
    border-left: 2px solid #5538FF;
  }
}

@media only screen and (max-width: 768px) {
  .BlogView {
    &__action {
      padding-bottom: 60px;
    }

    &__title {
      font-size: 28px;
      line-height: 42px;
    }

    &__description {
      margin-bottom: 28px;
    }

    &__action {
      margin-top: 40px;
      padding-bottom: 60px;
    }

    &__blogsTitle {
      font-size: 24px;
      line-height: 32px;
    }
  }

}