.crossChain {
  margin-bottom: 260px;

  h2 {
    background: linear-gradient(180deg, #FFFFFF 34.87%, #5C5983 85.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 80px;
    line-height: 80px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 80px;
  }
}

.slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;

  p {
    font-size: 20px;
    line-height: 28px;
  }
}

@media only screen and (max-width: 768px) {
  .crossChain {
    margin-bottom: 180px;

    h2 {
      font-size: 44px;
      line-height: 44px;
      margin-bottom: 60px;
    }
  }

  .slide {
    img {
      width: 75px;
      height: 75px;
    }

    p {
      font-size: 14px;
      line-height: 19px;
    }
  }
}