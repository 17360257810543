.letsStarted {
  border-radius: 40px;
  border: 1px solid #4427C2;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 10%;
  overflow: hidden;

  &__decorLeft {
    position: absolute;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    width: 748px;
    height: 445px;
    border-top-left-radius: 40px;
  }

  &__decorRight {
    position: absolute;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    width: 455px;
    height: 445px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
  }

  &__title {
    font-size: 80px;
    font-weight: 600;
    line-height: 80px;
    background: linear-gradient(180deg, #FFFFFF 34.87%, #5C5983 85.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    padding-bottom: 10px;
    margin-bottom: 30px;
    position: relative;
  }

  &__text {
    font-size: 18px;
    line-height: 25px;
    max-width: 610px;
    text-align: center;
    margin-bottom: 35px;
    position: relative;
  }

  &__actions {
    display: flex;
    align-items: center;
    column-gap: 25px;
    justify-content: start;
    position: relative;
  }

  &__actionBook {
    width: 202px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1000px;
    font-weight: 500;
    line-height: 16px;
    color: #fff;
  }

  &__actionPricing {
    position: relative;
    color: #fff;
    font-weight: 500;
    padding-right: 25px;
    font-size: 16px;

    &:after {
      position: absolute;
      content: "";
      display: block;
      width: 9px;
      height: 9px;
      top: 50%;
      right: 0;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: translateY(-50%) translateX(-50%) rotateZ(45deg);
    }
  }
}

@media only screen and (max-width: 1024px) {
  .letsStarted {
    &__title {
      font-size: 60px;
      line-height: 60px;
    }

    &__text {
      max-width: 500px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .letsStarted {
    padding: 50px 0;
    align-items: start;

    &__decorLeft {
      display: none;
    }

    &__decorRight {
      display: none;
    }

    &__title {
      text-align: start;
      font-size: 44px;
      line-height: 44px;
      padding: 0 30px;
    }

    &__text {
      text-align: start;
      padding: 0 30px;
    }

    &__actions {
      padding: 0 16px;
      justify-content: space-between;
      width: 100%;
    }

    &__actionBook {
      width: 162px;
      max-width: 162px;
      height: 48px;
    }
  }
}