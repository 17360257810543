.getStarted {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 100px;

  &__mainText {
    background: linear-gradient(180deg, #FFFFFF 34.87%, #5C5983 85.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 100px;
    font-weight: 600;
    line-height: 100px;
    letter-spacing: -0.04em;
    padding-bottom: 40px;
  }

  &__secondText {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 50px;
  }

  &__actions {
    display: flex;
    align-items: center;
    column-gap: 25px;
  }

  &__getStartedBtn {
    width: 202px;
    height: 56px;
    gap: 10px;
    border-radius: 1000px;
    font-weight: 500;
    letter-spacing: -0.033em;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-weight: 600;
    }
  }

  &__view {
    position: relative;
    font-weight: 600;
    line-height: 16;
    letter-spacing: -0.033em;
    padding-right: 25px;


    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 9px;
      height: 9px;
      top: 50%;
      right: 0;
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
      transform: translateY(-50%) translateX(-50%) rotateZ(45deg);
    }
  }
}

@media only screen and (max-width: 1360px) {
  .getStarted__mainText {
    font-size: 70px;
    line-height: 70px;
  }

  .getStarted__image {
    img {
      width: 450px;
    }
  }
}

@media only screen and (max-width: 960px) {
  .getStarted__mainText {
    font-size: 50px;
    line-height: 50px;
  }

  .getStarted__image {
    img {
      width: 370px;
    }
  }

  .getStarted__secondText {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .getStarted__mainText {
    font-size: 44px;
    line-height: 44px;
    padding-bottom: 20px;
  }

  .getStarted__image {
    text-align: center;
    margin-bottom: 20px;

    img {
      width: 307px;
    }
  }
  .getStarted__secondText {
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 40px;
  }

  .getStarted__actions {
    column-gap: 20px;
  }

  .getStarted__getStartedBtn {
    height: 48px;
  }
}

@media only screen and (min-width: 768px) {
  .getStarted {

    &__image {
      position: absolute;
      top: 15px;
      right: 0;
    }

    p {
      max-width: 55%;
    }
  }
}