.headerInfo {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin: 60px 0 40px;
    font-size: 80px;
    line-height: 80px;
    font-weight: 600;
    background: linear-gradient(180deg, #300097 0%, #10003F 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &__chooser {
    border-radius: 30px;
    background-color: #E1D9FF;
    height: 54px;
    //width: 294px;
    display: flex;
    padding: 0 4px;
    align-items: center;
    column-gap: 16px;
  }

  &__chooserItem {
    position: relative;
    width: 131px;
    height: 46px;

    input {
      width: 100%;
      height: 100%;
      padding: 0;
      opacity: 0;
      cursor: pointer;
    }

    p {
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      transition: all .15s;

      span {
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: #0D0131;
      }
    }
  }
}

.subTitle {
  margin-bottom: 50px;
  font-size: 28px;
  line-height: 36px;
  max-width: 774px;
  text-align: center;
}

.chooseActive {
  background-color: #FBF9FF;
  border-radius: 1000px;
}

@media only screen and (max-width: 768px) {
  .headerInfo {
    h2 {
      font-size: 44px;
      line-height: 44px;
    }
  }

  .subTitle {
    font-size: 20px;
    line-height: 26px;
  }
}