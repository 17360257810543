.auth {
    min-height: 100vh;
    padding: 50px 0;
    display: flex;
    justify-content: center;

    .logo {
        display: block;
        width: 78px;
        height: 35px;
        margin: 0 auto 80px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__content {
        max-width: 540px;
        width: 100%;
        margin-inline: 20px;
    }

    &__title {
        text-align: center;
        font-size: 1.875rem;
        line-height: 140%;
        font-weight: 600;
        margin-bottom: 40px;
    }

    .bottomLink {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        column-gap: 12px;
        line-height: 140%;

        &__link {
            font-weight: 600;
            color: inherit;
        }
    }
}

.form {
  
    &__group {
        position: relative;
        flex-grow: 1;

        &:not(:last-of-type) {
            margin-bottom: 16px;
        }
    }

    &__row {
        display: flex;
        column-gap: 20px;

        &:not(:last-of-type) {
            margin-bottom: 16px;
        }

        .form__group {
            margin-bottom: 0;
        }
    }
  
    .error {
        border: 1px solid #D72E4B;
    }
  
    &__input {
        display: inline-block;
        width: 100%;
        padding: 11px 12px;
        font-size: inherit;
        border: 1px solid #A9A9A9;

        &::placeholder {
            color: #A9A9A9;
        }

        &:focus {
            border: 1px solid #FFAC5E;
        }
    }
  
    &__groupPhone {
        display: flex;
        border: 1px solid #A9A9A9;

        .phoneSelect {
            min-width: 115px;
            border-right: 1px solid #A9A9A9;
        }

        .phoneInput {
            border: none;

            &:focus {
                border: none;
                outline: 1px solid #FFAC5E;
            }
        }
    }
  
    .terms {
  
        &__label {
            padding: 11px 0;
            display: flex;
            align-items: center;
            gap: 8px;
            color: #A9A9A9;
            line-height: 140%;

            input {
                width: 20px;
                height: 20px;
            }
        }
    }

    .rememberMe {

        &__label {
            padding: 11px 0;
            display: flex;
            align-items: center;
            gap: 8px;
            color: #A9A9A9;
            line-height: 140%;

            input {
                width: 20px;
                height: 20px;
            }
        }
    }

    &__forgotPassLink {
        padding: 11px 0;
        text-align: right;

        .link {
            font-size: 1rem;
            font-weight: 600;
            line-height: 140%;
            color: black;
        }
    }

    &__submitBtn {
        width: 100%;
        margin: 50px 0 30px;
        padding: 19px;
        color: white;
        background-color: #5538FF;
        transition: color 0.2s background-color 0.2s;

        &:hover {
            background-color: #4427C2;
        }

        &:disabled {
            color: #646464;
            background-color: #E0E0E0;
            cursor: default;
        }
    }
  
    .errorText {
        position: absolute;
        right: 10px;
        top: -14px;
        font-size: 10px;
        color: #D72E4B;
    }
}