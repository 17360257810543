.aboutUsWrapper {
  margin-bottom: 260px;
}

.aboutUs {
  display: flex;
  justify-content: space-around;
  column-gap: 40px;
  margin-bottom: 80px;

  h3 {
    font-size: 48px;
    line-height: 48px;
    font-weight: 600;
    background: linear-gradient(180deg, #FFFFFF 34.87%, #5C5983 85.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 80px;
  }

  &__info {
    max-width: 600px;
  }

  &__image {
    img {
      max-width: 494px;
    }
  }

  &__users {
    display: flex;
    align-items: center;
    column-gap: 40px;
    margin-bottom: 40px;

    span {
      font-size: 16px;
      line-height: 22px;
    }
  }

  &__text {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 60px;
  }

  &__action {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      width: 340px;
      height: 65px;
      border-radius: 1000px;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 12px;

      span {
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
      }
    }
  }
}

.stats {
  display: grid;
  gap: 60px;
  grid-template-areas:
            "item1 item1 item2 item2 item3 item3"
            "item4 item4 item4 item5 item5 item5";
}

.stats__item {
  display: flex;
  align-items: center;
  column-gap: 16px;
  justify-content: center;

  span {
    font-size: 54px;
    line-height: 54px;
    font-weight: 600;
    background: linear-gradient(180deg, #FFFFFF 34.87%, #5C5983 85.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    font-size: 24px;
    line-height: 24px;
    background: linear-gradient(180deg, #FFFFFF 34.87%, #5C5983 85.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@media only screen and (max-width: 1024px) {
  .aboutUs {
    &__info {
      max-width: 450px;
    }

    &__image {
      img {
        max-width: 354px;
      }
    }
  }

  .stats {
    display: grid;
    gap: 40px;
    grid-template-areas:
            "item1 item1 item2 item2 item3 item3"
            "item4 item4 item4 item5 item5 item5";
  }

  .stats__item {
    span {
      font-size: 44px;
      line-height: 44px;
    }

    p {
      font-size: 20px;
      line-height: 20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .aboutUsWrapper {
    margin-bottom: 180px;
  }

  .aboutUs {
    margin-bottom: 60px;

    h3 {
      margin-bottom: 40px;
      text-align: center;
      font-size: 44px;
      line-height: 44px;
    }

    &__info {
      max-width: 100%;
    }

    &__users {
      column-gap: 20px;
      margin-bottom: 50px;

      img {
        width: 108px;
        height: 44px;
      }

      span {
        font-weight: 300;
        font-size: 12px;
        line-height: 17px;
      }
    }

    &__text {
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 40px;
    }

    &__content {
      display: flex;
      flex-direction: column-reverse;
    }

    &__action {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        width: 280px;
        height: 48px;
        display: flex;
        align-items: center;
        column-gap: 12px;
        justify-content: center;

        span {
          font-size: 20px;
          line-height: 24px;
          font-weight: 500;
        }
      }
    }
  }

  .stats {
    display: grid;
    grid-template-areas: inherit;
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
  }

  .stats__item {
    justify-content: start;

    span {
      font-size: 44px;
      line-height: 44px;
    }

    p {
      font-size: 20px;
      line-height: 20px;
    }
  }

  .stats__item:nth-child(1) {
    p {
      max-width: 150px;
    }
  }
  .stats__item:nth-child(2) {
    p {
      max-width: 130px;
    }
  }
  .stats__item:nth-child(3) {
    p {
      max-width: 220px;
    }
  }
  .stats__item:nth-child(4) {
    p {
      max-width: 135px;
    }
  }
  .stats__item:nth-child(5) {
    p {
      max-width: 195px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .stats__item:nth-child(1) {
    grid-area: item1;

    p {
      max-width: 150px;
    }
  }
  .stats__item:nth-child(2) {
    grid-area: item2;

    p {
      max-width: 130px;
    }
  }
  .stats__item:nth-child(3) {
    grid-area: item3;

    p {
      max-width: 220px;
    }
  }
  .stats__item:nth-child(4) {
    grid-area: item4;

    p {
      max-width: 135px;
    }
  }
  .stats__item:nth-child(5) {
    grid-area: item5;

    p {
      max-width: 195px;
    }
  }
}