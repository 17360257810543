.startForm {
  height: 100vh;
}

.close {
  padding-top: 30px;
  display: flex;
  justify-content: flex-end;
}

.content {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  column-gap: 20px;

  &__info {
    h2 {
      font-size: 80px;
      line-height: 80px;
      font-weight: 600;
      background: linear-gradient(180deg, #FFFFFF 34.87%, #5C5983 85.71%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      max-width: 610px;
      margin-bottom: 20px;
    }

    p {
      max-width: 345px;
      font-size: 18px;
      line-height: 23px;
    }
  }

  &__form {
    max-width: 670px;
  }
}

.formFieldsWrapper {
  max-width: 670px;
  width: 100%;
}

.formFields {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;

  .formGroup {
    position: relative;
  }

  .input {
    width: 100%;
    height: 53px;
    font-size: 14px;
    line-height: 21px;
    background-color: transparent;
    border: 1px solid #4427C2;
    border-radius: 4px;
    padding-left: 32px;
    color: #fff;

    &:focus {
      border: 1px solid #FFAC5E;
    }
  }

  .error {
    border: 1px solid #D72E4B;
  }

  textarea {
    width: 100%;
    height: 208px;
    background-color: transparent;
    border: 1px solid #4427C2;
    border-radius: 4px;
    padding: 16px 16px 16px 32px;
    color: #fff;

    &:focus {
      border: 1px solid #FFAC5E;
    }
  }

  .errorText {
    position: absolute;
    right: 10px;
    top: -16px;
    font-size: 10px;
    color: #D72E4B;
  }
}

.submit {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  margin-top: 50px;

  button {
    height: 56px;
    border-radius: 1000px;

    span {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
    }
  }

  &__text {
    color: #FFFFFF99;
    opacity: 0.6;
    font-size: 12px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 1024px) {
  .content__info {
    h2 {
      font-size: 56px;
      line-height: 56px;
      font-weight: 600;
    }

    p {
      max-width: 280px;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .content {
    flex-direction: column;
    row-gap: 40px;
    margin-top: 40px;
  }

  .content__info {
    h2 {
      font-size: 44px;
      line-height: 44px;
      font-weight: 600;
    }
  }

  .formFields {
    input {
      height: 42px;
      padding-left: 20px;
    }

    textarea {
      height: 140px;
      padding-left: 20px;
    }
  }

  .submit {
    margin-top: 40px;
    row-gap: 24px;
    padding-bottom: 50px;
  }

  .startForm {
    height: 100%;
  }

  .close {
    padding-top: 25px;
  }
}