.apiDocs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 260px;
  background-repeat: no-repeat;
  position: relative;

  &__decor {
    pointer-events: none;
    background-repeat: no-repeat;
    position: absolute;
    width: 1092px;
    height: 1270px;
    top: 38%;
    transform: translateY(-50%);
    right: -40px;
  }

  &__info {
    max-width: calc(50% - 150px);
    position: relative;
  }

  h3 {
    background: linear-gradient(180deg, #FFFFFF 34.87%, #5C5983 85.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 80px;
    line-height: 80px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 40px;
  }

  &__description {
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 60px;
  }

  &__action {
    height: 54px;
    width: 171px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1000px;
    font-size: 20px;
    line-height: 20px;
    font-weight: 500;
    color: #fff;
  }

  &__image {
    margin-right: -40px;
    position: relative;
  }
}

@media only screen and (max-width: 768px) {
  .apiDocs {
    flex-direction: column;
    margin-bottom: 180px;
    overflow: hidden;
    margin-left: -20px;
    width: calc(100% + 40px);
    padding: 0 20px;

    &__decor {
      //width: 100%;
      //height: 100%;
      height: 667px;
      width: 573px;
      left: -52px;
      background-size: contain;
    }

    h3 {
      font-size: 44px;
      line-height: 44px;
    }

    &__info {
      max-width: 100%;
    }

    &__description {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 40px;
    }

    &__actionWrapper {
      display: flex;
      justify-content: center;
    }

    &__image {
      margin: 0 0 40px 0;
      height: 260px;
      position: relative;

      img {
        position: absolute;
        top: 0;
        right: -20px;
        max-width: 360px;
        max-height: 260px;
      }
    }
  }
}