.tariffs {
  display: flex;
  column-gap: 28px;
}

.tariff {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 80px;
  border-radius: 40px;
  padding: 32px 24px;
  border: 1px solid #5538FF33;
  background-color: #FEFDFF;
  transition: all .15s;

  p, span, li, h4 {
    color: #0D0131 !important;
  }

  h4 {
    color: #5538FF !important;
    font-size: 28px;
    line-height: 36px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  &__subName {
    padding-bottom: 40px;
    border-bottom: 1px solid #5538FF33
  }

  &__priceBox {
    margin: 40px 0 24px;
    height: 93px;
  }

  &__price {
    font-size: 54px;
    font-weight: 600;
    line-height: 70px;
    letter-spacing: -0.02em;
  }

  &__priceInfo {
    font-size: 18px;
    line-height: 23px;
    opacity: 0.5;
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    border-radius: 100px;
    background-color: #5538FF;
    transition: all .15s;
    width: 100%;
    margin-bottom: 24px;

    span {
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      color: #fff !important;
      transition: all .15s;
    }
  }

  &__options {
    font-weight: 500;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 16px;
  }

  ul {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    margin-bottom: 50px;

    li {
      padding-left: 32px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 40%;
        transform: translateY(-50%) rotateZ(-45deg);
        width: 14px;
        height: 8px;
        border-bottom: 1px solid #5538FF;
        border-left: 1px solid #5538FF;
      }
    }
  }

  &__learnMore {
    margin-top: auto;
    display: flex;
    justify-content: center;

    a {
      position: relative;
      width: 120px;
      display: block;
      color: #5538FF !important;
      font-weight: 500;
      line-height: 16px;
      text-align: center;

      &:after {
        right: 0;
        top: 25%;
        position: absolute;
        content: '';
        width: 8px;
        height: 8px;
        border-top: 1px solid #5538FF;
        border-right: 1px solid #5538FF;
        transform: rotateZ(45deg);
      }
    }
  }
}

.tariff:hover {
  background-color: #5538FF;

  p, span, li, h4 {
    color: #FEFDFF !important;
  }

  h4 {
    color: #FEFDFF !important;
  }
}

.tariff:hover .tariff__action {
  background-color: #FFDA18;
  transition: all .15s;

  span {
    color: #5538FF !important;
    transition: all .15s;
  }
}

.tariff:hover .tariff__learnMore {
  a {
    color: #fff !important;

    &:after {
      border-top: 1px solid #fff;
      border-right: 1px solid #fff;
    }
  }
}

.tariff:hover .tariff__subName {
  border-bottom: 1px solid #FFFFFF33;
}

.tariff:hover ul li:before {
  border-bottom: 1px solid #FEFDFF;
  border-left: 1px solid #FEFDFF;
}

@media only screen and (max-width: 768px) {
  .tariffs {
    flex-direction: column;
    row-gap: 20px;
    margin-top: 40px;
  }

  .tariff {
    margin-top: 0;
    padding: 32px 24px;

    &__subName {
      padding-bottom: 20px;
    }

    &__priceBox {
      margin: 20px 0 24px;
      height: 80px;
    }

    &__price {
      font-size: 44px;
      font-weight: 600;
      line-height: 50px;
    }

    ul {
      margin-bottom: 30px;
    }
  }
}