*{padding: 0;margin: 0;border: 0;}
*,*:before, *:after{-moz-box-sizing: border-box;-webkit-box-sizing: border-box;box-sizing: border-box;}
:focus, :active{outline: none;}
a:focus,a:active{outline: none;}
nav, footer, header, aside{display: block;}
html, body{height: 100%;width: 100%;font-size: 100%;-ms-text-size-adjust: 100%;-moz-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;}
input, button, textarea{font-family: inherit;}
input::-ms-clear{display: none;}
button{cursor: pointer;}
button::-moz-focus-inner{padding: 0;border: 0;}
a, a:visited{text-decoration: none;}
a:hover{text-decoration: none;}
ul li{list-style: none;}
img{vertical-align: top;}
h1,h2,h3,h4,h5,h6 {font-size: inherit;font-weight: 400 ;}
textarea{resize: none;}
a, button {-webkit-tap-highlight-color: transparent;}
button {
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
}

.colorBtn {
  background-image: linear-gradient(to right, #ff00cc 0%, #333399  51%, #ff00cc  100%);
  transition: 0.4s;
  background-size: 200% auto;
  color: #fff !important;

  &:hover {
    background-position: right center;
  }
}

.lightWrapper, .grayWrapper {
  background-color: #FEFDFF;

  &__content {
    max-width: 1440px;
    padding: 0 40px 48px;
    margin: 0 auto;
    background-repeat: no-repeat;
    position: relative;

    a, p, span, li, h1, h2, h3, h4, h5, h6 {
      color: #0D0131;
    }
  }

}

.grayWrapper {
  background-color: #5538FF05;
}

.darkWrapper {
  a, p, span, li, h1, h2, h3, h4, h5, h6 {
    color: #ffffff;
  }

  background-repeat: no-repeat;
  position: relative;
  background-color: #0D0131;

  &__content {
    max-width: 1440px;
    padding: 0 40px 48px;
    margin: 0 auto;
  }
}


@media only screen and (max-width: 768px) {
  .lightWrapper, .darkWrapper, .grayWrapper {
    &__content {
      padding: 0 20px 24px;
    }
  }
}