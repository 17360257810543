.whatIS {
  margin-bottom: 260px;
  position: relative;

  h2 {
    font-size: 80px;
    font-weight: 600;
    line-height: 80px;
    background: linear-gradient(180deg, #FFFFFF 34.87%, #5C5983 85.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-bottom: 60px;
    position: relative;
  }

  &__items {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
  }

  &__integration, &__perfomace, &__security {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    row-gap: 20px;

    p {
      font-size: 18px;
      line-height: 33px;
      font-weight: 500;
    }
  }

  &__integration {
    max-width: 250px;
  }

  &__perfomace {
    max-width: 354px;
  }

  &__security {
    max-width: 320px;
  }
}

@media only screen and (max-width: 768px) {
  .whatIS {
    margin-bottom: 180px;

    h2 {
      font-size: 40px;
      line-height: 40px;
    }

    &__items {
      flex-direction: column;
      row-gap: 30px;
    }

    &__integration, &__perfomace, &__security {
      max-width: 100%;
      padding: 0 20px;

      img {
        width: 36px;
        height: 36px;
      }
      p {
        line-height: 25px;
      }
    }
  }
}

@media only screen and (min-width: 767px) {
  .whatIS {
    &__decor {
      position: absolute;
      pointer-events: none;
      top: 50%;
      left: -40px;
      background-repeat: no-repeat;
      width: 476px;
      height: 1111px;
      display: block;
      transform: translateY(-50%);
    }
  }
}

