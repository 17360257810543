.blogTitle {
  font-size: 54px;
  line-height: 70px;
  font-weight: 600;
  background: linear-gradient(180deg, #300097 0%, #10003F 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 60px;
}


@media only screen and (max-width: 768px) {
  .blogTitle {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 30px;
  }
}
