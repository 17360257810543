.whatAPI {
  margin-bottom: 260px;

  h2 {
    background: linear-gradient(180deg, #FFFFFF 34.87%, #5C5983 85.71%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 80px;
    line-height: 80px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 80px;
  }
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 13px;
}

.tabs {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.tab {
  position: relative;
  border: 1px solid #4427C2;
  border-radius: 20px;
  cursor: pointer;
  background-color: transparent;
  transition: all .15s;
  height: 96px;
  overflow: hidden;

  input {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  p {
    pointer-events: none;
    display: flex;
    align-items: center;
    column-gap: 30px;
    padding: 30px 50px;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    font-size: 32px;
    line-height: 32px;
    font-weight: 500;
    cursor: pointer;
  }
}

.tab:has(input:checked) {
  background-color: #4427C2;
}

.content {
  padding: 40px;
  border-radius: 40px;
  border: 1px solid #4427C2;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: right;

  span {
    font-size: 18px;
    line-height: 25px;
  }

  p {
    margin-top: auto;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
  }
}

@media only screen and (max-width: 1200px) {
  .tab {
    p {
      padding: 15px 20px;
      font-size: 26px;
      line-height: 26px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .tab {
    p {
      padding: 15px 15px;
      font-size: 22px;
      line-height: 22px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .whatAPI {
    margin-bottom: 180px;

    h2 {
      font-size: 44px;
      line-height: 44px;
    }
  }

  .wrapper {
    display: grid;
    grid-template-columns: 1fr;
  }

  .tab {
    height: 64px;
    display: flex;
    flex-direction: column;
    transition: all .15s;

    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 64px
    }

    p {
      padding: 24px 20px;
      font-size: 24px;
      line-height: 24px;
      width: 100%;
      height: 64px;
      left: 0;
      top: 0;
    }
  }

  .secondContent {
    padding: 16px 20px 24px;
    pointer-events: none;
    height: 0;
    opacity: 0;
  }

  .tab:has(input:checked) {
    height: auto;
  }

  .tab:has(input:checked) div {
    height: auto;
    margin-top: 64px;
    opacity: 1;
  }
}