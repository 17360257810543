.blogMain {
  position: relative;
  padding-bottom: 160px;

  h3 {
    font-weight: 600;
    font-size: 54px;
    line-height: 70px;
    background: linear-gradient(180deg, #300097 0%, #10003F 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    max-width: 1000px;
    margin-bottom: 20px;
  }
}

.decor {
  position: absolute;
  top: 50%;
  right: -40px;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-position: 120% 50%;
  z-index: 9;
  width: 753px;
  height: 780px;
  pointer-events: none;
}

.date {
  font-size: 18px;
  color: #0D013180;
  opacity: 0.5;
  display: block;
  margin-bottom: 24px;
}

.text {
  font-size: 24px;
  line-height: 34px;
  margin-bottom: 40px;
}

.action {
  color: #5538FF;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
}


@media only screen and (max-width: 768px) {
  .blogMain {
    padding-bottom: 80px;

    h3 {
      font-size: 34px;
      line-height: 42px;
    }
  }

  .text {
    font-size: 18px;
    line-height: 24px;
  }
}