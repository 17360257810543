.useCases {
  display: grid;
  gap: 20px;
  margin-bottom: 260px;
}

.title {
  font-size: 44px;
  line-height: 44px;
  font-weight: 600;
  background: linear-gradient(180deg, #FFFFFF 34.87%, #5C5983 85.71%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: 40px;
}

.useCases__item {
  border-radius: 40px;
  border: 1px solid #4427C2;
  padding: 30px;
  max-width: 325px;
  //max-height: 325px;

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;

  h4 {
    font-size: 30px;
    line-height: 30px;
    font-weight: 600;
  }

  p {
    margin-top: auto;
    font-size: 18px;
    line-height: 25px;
  }
}


@media only screen and (max-width: 768px) {
  .useCases {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 180px;
    min-height: 278px;
  }

  .useCases__item {
    max-width: 100%;
    min-height: 278px;
  }

  .useCases__item:nth-child(1) {
    overflow: hidden;

    h4 {
      display: none;
    }

    p {
      margin-top: 0;
    }

    img {
      position: absolute;
      right: 0;
      bottom: -65px;
      max-width: 205px;
    }
  }

  .useCases__item:nth-child(6) {
    overflow: hidden;

    p {
      margin-top: 24px;
    }

    img {
      position: absolute;
      right: 0;
      bottom: -40px;
      max-width: 180px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .useCases {
    grid-template-areas:
            "item1 item2 item3 item4"
            "item1 item5 item6 item6";
  }

  .useCases__item {
    min-height: 325px;
  }

  .useCases__item:nth-child(1) {
    grid-area: item1;
    min-height: 687px;

    p {
      margin-top: 32px;
    }

    img {
      margin-top: auto;
      position: static;
    }

    h4 {
      font-size: 48px;
      line-height: 48px;
      font-weight: 600;
      background: linear-gradient(180deg, #FFFFFF 34.87%, #5C5983 85.71%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  .useCases__item:nth-child(2) {
    grid-area: item2;
  }
  .useCases__item:nth-child(3) {
    grid-area: item3;
  }
  .useCases__item:nth-child(4) {
    grid-area: item4;
  }
  .useCases__item:nth-child(5) {
    grid-area: item5;
  }
  .useCases__item:nth-child(6) {
    grid-area: item6;
    max-width: 670px;
    overflow: hidden;

    p {
      max-width: 200px;
    }

    img {
      position: absolute;
      top: 65px;
      right: -65px;
    }
  }
}

@media only screen and (min-width: 1320px) {
  .useCases__item:nth-child(6) {
    p {
      max-width: 270px;
    }

    img {
      top: 51px;
      right: 0;

    }
  }
}