.trustedBy {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  align-items: center;
  margin-bottom: 260px;
}

.title {
  font-size: 28px;
  line-height: 28px;
}

.companies {
  display: flex;
  align-items: center;
  column-gap: 40px;
  width: 100%;
  justify-content: center;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 20px;
    line-height: 20px;
  }

  .companies {
    overflow: auto;
    margin-left: 0;
    width: calc(100% + 40px);
  }
}